import { thunk } from 'easy-peasy';
import {
  getLocationAPICall,
  getLocationsAPICall,
  upsertLocationAPICall,
} from '../../api/locations';

const thunks = {
  getLocationsThunk: thunk(async (actions, tenantId, helpers) => {
    actions.setLoadingAction(true);
    try {
      const response = await getLocationsAPICall(
        tenantId || helpers.getState().tenant.input.id
      );
      actions.setLocationSearchResultsAction(response.data);
      actions.setLoadingAction(false);
      return response.data;
    } catch (err) {
      actions.setAlertThunk({
        type: 'error',
        message: err.response.data,
      });
    }

    actions.setLoadingAction(false);
  }),
  getLocationThunk: thunk(async (actions, tenantId) => {
    try {
      const response = await getLocationsAPICall(tenantId);
      return response.data;
    } catch (err) {
      actions.setAlertThunk({
        type: 'error',
        message: err.response.data,
      });
    }
  }),
  upsertLocationThunk: thunk(
    async (actions, { navigation, input, message }, helpers) => {
      actions.setLoadingAction(true);

      try {
        await upsertLocationAPICall({
          ...input,
          tenantId: helpers.getState().tenant.input.id,
          logo: helpers.getState().document,
        });
        actions.setAlertThunk({
          type: 'success',
          message,
        });
        navigation.navigate('Locations');
      } catch (err) {
        actions.setAlertThunk({
          type: 'error',
          message: err.response.data,
        });
      }

      actions.setLoadingAction(false);
    }
  ),
};

export default thunks;
