import Row from '../../presentational/Row';
import Col from '../../presentational/Col';
import styled from 'styled-components/native';
import { View } from 'react-native';
import { vhToDp } from '../../../utilities/general';
import FormSection from '../../presentational/FormSection';
import { ScrollView } from 'react-native-gesture-handler';
import StylishSelect from '../../presentational/Select';
import Button from '../../presentational/Button';
import UniversalTextInput from '../../presentational/TextInput';

const FormContainer = styled(View)`
  flex: 1;
  height: 100%;
`;

const FormHeader = styled.View`
  flex-direction: row;
  padding: ${vhToDp(3)}px;
  padding-right: 0;
  border-bottom-width: 1px;
  border-bottom-color: #edf2f7;
  width: 100%;
  justify-content: space-between;
`;

const FormTitle = styled.Text`
  font-size: ${vhToDp(2.4)}px;
  font-weight: 700;
  color: #00205b;
`;

const FormSubtitle = styled.Text`
  font-size: ${vhToDp(1.4)}px;
  color: #6b7280;
  margin-top: ${vhToDp(0.5)}px;
`;

function ToolTransferForm({
  approveTransfer,
  fromLocations,
  getTools,
  input,
  message = 'Transfer tools across tenants',
  newTransfer,
  receiveTransfer,
  rejectTransfer,
  requestTransfer,
  selectTenant,
  setInput,
  storageLocations,
  tenantOptions,
  title = 'Transfer Tool',
  toLocations,
  tools,
  toolStatuses,
  ...props
}) {
  return (
    <FormContainer>
      <FormHeader>
        <View style={{ justifyContent: 'center' }}>
          <FormTitle>{title}</FormTitle>
          <FormSubtitle>{message}</FormSubtitle>
        </View>
        <Row
          style={{
            margin: 5,
          }}
        >
          {input.status === 'New' && (
            <Col>
              <Button
                disabled={
                  !(
                    input.fromtoollocationid &&
                    input.totoollocationid &&
                    input.toolid
                  )
                }
                onPress={async () => {
                  requestTransfer();
                }}
                fullWidth
              >
                Request
              </Button>
            </Col>
          )}
          {input.status === 'Pending' && (
            <>
              <Col xs={12} sm={4}>
                <Button.Secondary
                  style={{ marginLeft: '4px' }}
                  onPress={async () => {
                    newTransfer();
                  }}
                  fullWidth
                >
                  New
                </Button.Secondary>
              </Col>
              <Col xs={12} sm={4}>
                <Button.Tertiary
                  onPress={async () => {
                    rejectTransfer();
                  }}
                  fullWidth
                >
                  Reject
                </Button.Tertiary>
              </Col>
              <Col xs={12} sm={4}>
                <Button
                  disabled={!input.trackingnumber}
                  onPress={async () => {
                    await approveTransfer();
                  }}
                  fullWidth
                >
                  Approve
                </Button>
              </Col>
            </>
          )}
          {input.status === 'In Transit' && (
            <>
              <Col xs={12} sm={6}>
                <Button.Secondary
                  style={{ marginLeft: '4px' }}
                  onPress={async () => {
                    newTransfer();
                  }}
                  fullWidth
                >
                  New
                </Button.Secondary>
              </Col>
              <Col xs={12} sm={6}>
                <Button
                  disabled={!(input.toStorageLocation && input.toolStatus)}
                  onPress={async () => {
                    await receiveTransfer();
                  }}
                  fullWidth
                >
                  Receive
                </Button>
              </Col>
            </>
          )}
        </Row>
      </FormHeader>
      <ScrollView>
        <Row>
          <Col xs={12} md={6}>
            <FormSection title={'From Information'}>
              <StylishSelect
                searchable={false}
                options={tenantOptions}
                value={input?.fromtenantid}
                onChange={async (fromtenantid) => {
                  selectTenant({ fromtenantid });
                }}
                label='Tenant'
                disabled={!!input.id}
                required
              ></StylishSelect>
              <StylishSelect
                searchable={false}
                options={fromLocations}
                value={input?.fromtoollocationid}
                onChange={async (locationId) => {
                  await setInput({ ...input, fromtoollocationid: locationId });
                  await getTools({
                    tenantid: input.fromtenantid,
                    userlocationid: locationId,
                  });
                }}
                label='Location'
                disabled={!!input.id}
                required
              ></StylishSelect>
              <StylishSelect
                searchable={true}
                options={tools}
                value={input?.toolid}
                onChange={(toolid) => {
                  setInput({ ...input, toolid: toolid });
                }}
                label='Available Tools'
                disabled={!!input.id}
                required
              ></StylishSelect>
            </FormSection>
          </Col>
          <Col xs={12} md={6}>
            <FormSection title='To Information'>
              <StylishSelect
                searchable={false}
                options={tenantOptions}
                value={input?.totenantid}
                onChange={async (totenantid) => {
                  selectTenant({ totenantid });
                }}
                label='Tenant'
                disabled={!!input.id}
                required
              ></StylishSelect>
              <StylishSelect
                searchable={false}
                options={toLocations}
                value={input?.totoollocationid}
                onChange={(locationId) => {
                  setInput({ ...input, totoollocationid: locationId });
                }}
                label='Location'
                disabled={!!input.id}
                required
              ></StylishSelect>
            </FormSection>
          </Col>
        </Row>
        <Row>
          {(input.status === 'Pending' || input.status === 'In Transit') && (
            <Col xs={12} md={6}>
              <FormSection title='Shipping Details'>
                <UniversalTextInput
                  placeholder='Type in Tracking Number'
                  label='Tracking Number'
                  value={input.trackingnumber}
                  onChangeText={(trackingnumber) =>
                    setInput({ ...input, trackingnumber })
                  }
                  required
                  editable={input.status === 'Pending'}
                />
                <UniversalTextInput
                  placeholder='Type in Value'
                  label='Value'
                  value={input.amount}
                  onChangeText={(amount) => setInput({ ...input, amount })}
                  editable={input.status === 'Pending'}
                />
              </FormSection>
            </Col>
          )}
          {input.status === 'In Transit' && (
            <Col xs={12} md={6}>
              <FormSection title='Receiving Details'>
                <StylishSelect
                  searchable={false}
                  options={storageLocations}
                  value={input?.toStorageLocation}
                  onChange={(toStorageLocation) =>
                    setInput({ ...input, toStorageLocation })
                  }
                  label='Storage Location'
                  required
                ></StylishSelect>
                <StylishSelect
                  searchable={false}
                  options={toolStatuses}
                  value={input?.toolStatus}
                  onChange={(toolStatus) => setInput({ ...input, toolStatus })}
                  label='Tool Status'
                  required
                ></StylishSelect>
              </FormSection>
            </Col>
          )}
        </Row>
      </ScrollView>
    </FormContainer>
  );
}

export default ToolTransferForm;
