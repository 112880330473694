import { action, thunk } from 'easy-peasy';
import {
  checkoutToolAPICall,
  getToolsAPICall,
  upsertBulkToolsAPICall,
  upsertToolsAPICall,
  reserveToolAPICall,
  filterToolsAPICall,
  getReservedToolsAPICall,
  checkToolBeforeRequestAPICall,
  upsertBrokenToolAPICall,
  getBrokenToolHistoryAPICall,
  getToolCategoryListAPICall,
  upsertToolCategoryAPICall,
  getOEMStatusListAPICall,
  upsertOEMStatusListAPICall,
  getToolHistoryAPICall,
  getToolTransfersAPICall,
  upsertToolTransferAPICall,
  removeToolTransfersAPICall,
  upsertAllToolStatusAPICall,
  getToolUsageAPICall,
  upsertToolUsageAPICall,
} from '../../api/tool';
import { getLocationsAPICall } from '../../api/locations';
import { getDocumentAPICall, upsertDocumentAPICall } from '../../api/document';
import {
  getInspectionHistoryAPICall,
  getInspectionItemAPICall,
  getInspectionResultsAPICall,
  upsertToolInspectionResultAPICall,
} from '../../api/inspection';
import {
  getUpcomingReportsAPICall,
  getToolsByEngineAPICall,
} from '../../api/report';

const thunks = {
  getToolsThunk: thunk(async (actions, data, helpers) => {
    actions.setLoadingAction(true);
    const { id } = data;
    try {
      const response = await getToolsAPICall({
        id: id || 0,
        tenantId:
          helpers.getState().tenant.input.id ||
          helpers.getState().auth.user.tenantId,
      });
      actions.setToolsSearchResultsAction(response.data);
    } catch (err) {
      actions.setAlertThunk({
        type: 'error',
        message: err.response.data,
      });
    }

    actions.setLoadingAction(false);
  }),
  getToolsByLocationThunk: thunk(async (actions, data, helpers) => {
    const { tenantId, userlocationid } = data;
    try {
      const response = await getToolsAPICall({
        tenantId,
        userlocationid,
      });
      return response.data;
    } catch (err) {
      actions.setAlertThunk({
        type: 'error',
        message: err.response.data,
      });
    }
  }),
  getToolThunk: thunk(async (actions, id, helpers) => {
    actions.setLoadingAction(true);

    const tenantId =
      helpers.getState().tenant.input.id ||
      helpers.getState().auth.user.tenantId;

    try {
      const toolResponse = await getToolsAPICall({
        id,
        tenantId,
      });
      actions.setToolsInputAction(toolResponse.data[0]);
    } catch (err) {
      console.log(err);
      actions.setAlertThunk({
        type: 'error',
        message: err.response.data,
      });
    }

    actions.setLoadingAction(false);
  }),
  upsertToolsThunk: thunk(
    async (actions, { navigation, input, message }, helpers) => {
      actions.setLoadingAction(true);

      try {
        const tool = await upsertToolsAPICall({
          ...input,
          tenantId: helpers.getState().tenant.input.id,
          loadTestDueDate: helpers.getState().tools.input.loadTestRequired
            ? helpers.getState().tools.input.loadTestDueDate
            : '',
        });

        const documents = helpers.getState().documentsToAdd;

        if (documents.length > 0) {
          const requests = documents.map((doc) => {
            if (!doc.id) {
              upsertDocumentAPICall({
                ...doc,
                tenantId: helpers.getState().tenant.input.id,
                toolid: tool.data.toolId,
                id: null,
              });
            }
          });

          await Promise.all(requests);
        }

        actions.setExtraDocumentsToolIdAction(tool.data.toolId);
        actions.setInspectionsToolIdAction(tool.data.toolId);
        actions.resetToolsInputAction();
        actions.setAlertThunk({
          type: 'success',
          message,
        });

        navigation.goBack();
      } catch (err) {
        console.log(err);
        actions.setAlertThunk({
          type: 'error',
          message: err.response.data,
        });
      }

      actions.setLoadingAction(false);
    }
  ),
  upsertToolThunk: thunk(async (actions, input, helpers) => {
    try {
      await upsertToolsAPICall(input);

      actions.resetToolsInputAction();
      actions.setAlertThunk({
        type: 'success',
        message: 'Tool Updated',
      });
    } catch (err) {
      console.log(err);
      actions.setAlertThunk({
        type: 'error',
        message: err.response.data,
      });
    }
  }),
  upsertBulkToolsThunk: thunk(
    async (actions, { navigation, input, message }, helpers) => {
      actions.setLoadingAction(true);

      try {
        const response = await upsertBulkToolsAPICall({
          tenantId: helpers.getState().tenant.input.id,
          base64: input,
        });

        if (
          response &&
          response.data &&
          response.data.failedImportTools &&
          response.data.failedImportTools.length
        ) {
          response.data.failedImportTools.map((item, index) => {
            actions.setAlertThunk({
              type: 'error',
              message: `Failed to import ${item.nomenclature}`,
            });
          });
        }

        if (
          response &&
          response.data &&
          response.data.importedTools &&
          response.data.importedTools.length
        ) {
          actions.setAlertThunk({
            type: 'success',
            message: `Successfully imported ${response.data.importedTools.length} items`,
          });
        }
      } catch (err) {
        actions.setAlertThunk({
          type: 'error',
          message: err.response.data,
        });
      }

      actions.setLoadingAction(false);
    }
  ),
  checkoutToolThunk: thunk(
    async (actions, { navigation, input, message }, helpers) => {
      actions.setLoadingAction(true);
      try {
        const validTools = Object.values(helpers.getState().tools.checkoutMap);
        const ids = validTools.map((item) => item.id).join();
        await checkoutToolAPICall({
          ...helpers.getState().tools.checkoutInput,
          tenantId: parseInt(helpers.getState().auth.user.tenantId),
          toolId: ids,
        });

        actions.setAlertThunk({
          type: 'success',
          message: `Checked Out Tools Successfully`,
        });

        actions.setCheckinToolListAction([]);
        actions.setCheckoutToolListAction([]);
      } catch (err) {
        console.log(err);
        actions.setAlertThunk({
          type: 'error',
          message: err.response.data,
        });
      }

      actions.setLoadingAction(false);
    }
  ),
  checkinToolThunk: thunk(
    async (actions, { navigation, input, message }, helpers) => {
      actions.setLoadingAction(true);
      try {
        await checkoutToolAPICall({
          tenantId: parseInt(helpers.getState().auth.user.tenantId), //parseInt(item.tenantId),
          toolId:
            helpers.getState().tools.validCheckInToolList !== ''
              ? helpers.getState().tools.validCheckInToolList
              : helpers.getState().tools.checkinToolList, //parseInt(helpers.getState().tenant.input.id),
          toolStatusCode: 'AV',
          toolStatusDescription: 'Available',
          workOrder: '',
          engineserialNumber: '',
        });
        actions.setAlertThunk({
          type: 'success',
          message: `Checked In Tools Successfully`,
        });

        actions.setCheckinToolListAction([]);
        actions.setCheckoutToolListAction([]);
      } catch (err) {
        console.log(err);
        actions.setAlertThunk({
          type: 'error',
          message: err.response.data,
        });
      }

      actions.setLoadingAction(false);
    }
  ),
  reserveSingleToolThunk: thunk(async (actions, { input }, helpers) => {
    actions.setLoadingAction(true);
    try {
      await reserveToolAPICall({
        ...input,
        tenantId: parseInt(helpers.getState().tenant.input.id),
      });

      const response = await getReservedToolsAPICall({
        toolId: helpers.getState().tools.input.id,
        tenantId: parseInt(helpers.getState().tenant.input.id),
      });

      actions.setReservedToolsAction(response.data);

      actions.setReservationInputAction({
        id: null,
        reservationstartDate: '',
        reservationendDate: '',
        reservationworkOrder: '',
        reservationengineNumber: '',
        reservationNotes: '',
        cancelReservation: 'N',
      });

      actions.setAlertThunk({
        type: 'success',
        message: `Reserved Tool Successfully`,
      });
    } catch (err) {
      console.log(err);
      actions.setAlertThunk({
        type: 'error',
        message: err.response.data,
      });
    }

    actions.setLoadingAction(false);
  }),
  reserveToolThunk: thunk(
    async (actions, { navigation, input, message }, helpers) => {
      actions.setLoadingAction(true);
      try {
        await reserveToolAPICall({
          ...input,
          tenantId: parseInt(helpers.getState().auth.user.tenantId),
          toolId: helpers.getState().tools.validCheckOutToolList,
        });
        actions.setAlertThunk({
          type: 'success',
          message: `Reserved Tools Successfully`,
        });

        actions.setCheckinToolListAction([]);
        actions.setCheckoutToolListAction([]);
        const response = await getToolsAPICall({
          id: 0,
          tenantId: helpers.getState().auth.user.tenantId,
        });
        actions.setToolsSearchResultsAction(response.data);
      } catch (err) {
        console.log(err);
        actions.setAlertThunk({
          type: 'error',
          message: err.response.data,
        });
      }

      actions.setLoadingAction(false);
    }
  ),
  cancelReservationToolThunk: thunk(async (actions, data, helpers) => {
    actions.setLoadingAction(true);
    try {
      await reserveToolAPICall({
        id: helpers.getState().tools.reservationInput.id,
        WPId: '',
        tenantId:
          parseInt(helpers.getState().tenant.input.id) ||
          parseInt(helpers.getState().auth.user.tenantId),
        toolId: helpers.getState().tools.input.id,
        reservationstartDate:
          helpers.getState().tools.reservationInput.reservationstartDate,
        reservationendDate:
          helpers.getState().tools.reservationInput.reservationendDate,
        reservationworkOrder: '',
        reservationengineNumber: '',
        reservationNotes: '',
        cancelReservation: 'Y',
      });
      actions.setAreYouSureModalVisibleAction(false);

      const response = await getReservedToolsAPICall({
        toolId: helpers.getState().tools.input.id,
        tenantId: parseInt(helpers.getState().tenant.input.id), //parseInt(helpers.getState().auth.user.tenantId),
      });

      actions.setReservedToolsAction(response.data);

      actions.setAlertThunk({
        type: 'success',
        message: `Canceled Reservation Successfully`,
      });

      // actions.setCheckinToolListAction([]);
      // actions.setCheckoutToolListAction([]);
      // const response = await getToolsAPICall({
      //   id: 0,
      //   tenantId: helpers.getState().auth.user.tenantId,
      // });
      // actions.setToolsSearchResultsAction(response.data);
    } catch (err) {
      console.log(err);
      actions.setAlertThunk({
        type: 'error',
        message: err.response.data,
      });
    }

    actions.setLoadingAction(false);
  }),
  getToolInspectionResultsThunk: thunk(async (actions, data, helpers) => {
    actions.setLoadingAction(true);
    try {
      const response = await getInspectionResultsAPICall({
        tenantId: parseInt(helpers.getState().auth.user.tenantId),
        toolId: helpers.getState().tools.inspectionInput.toolId,
      });

      actions.setToolInspectionHistoryAction(response.data);
    } catch (err) {
      console.log(err);
      actions.setAlertThunk({
        type: 'error',
        message: err.response.data,
      });
    }
    actions.setLoadingAction(false);
  }),
  upsertToolInspectionResultThunk: thunk(
    async (actions, { input, message }, helpers) => {
      actions.setLoadingAction(true);

      try {
        let res = await upsertToolInspectionResultAPICall({
          ...helpers.getState().tools.inspectionInput,
          tenantId:
            helpers.getState().tenant.input.id ||
            helpers.getState().auth.user.tenantId,
          toolId:
            helpers.getState().tools.selectedToolPreCheck.id ||
            helpers.getState().workPackage.selectedWPPreCheck.toolid ||
            helpers.getState().tools.input.id,
        });
        actions.setInspectionTypeId(
          helpers.getState().tools.inspectionInput.inspectiontypeId
        );
        actions.setToolInspectionResultInputAction({
          id: null,
          inspectiontypeId: null,
          inspectionTypeName: '',
          inspectionitemId: null,
          inspectionItemName: '',
          inspectionItemDesc: '',
          inspectedBy: '',
          inspectionResponse: '',
          inspectionDate: '',
          toolId:
            helpers.getState().tools.selectedToolPreCheck.id ||
            helpers.getState().workPackage.selectedWPPreCheck.toolid,
        });
      } catch (err) {
        console.log(err);
        actions.setAlertThunk({
          type: 'error',
          message: err.response.data,
        });
      }

      actions.setLoadingAction(false);
    }
  ),
  filterToolsThunk: thunk(async (actions, data, helpers) => {
    actions.setLoadingAction(true);
    const isAdmin = helpers
      .getState()
      .auth.user.role.toLowerCase()
      .includes('admin');

    try {
      if (data == 'All') {
        const body = {
          id: 0,
          tenantId: helpers.getState().auth.user.tenantId,
        };
        if (!isAdmin) {
          body.userlocationid = helpers.getState().auth.user.locationId;
        }
        const response = await getToolsAPICall(body);
        actions.setToolsSearchResultsAction(response.data);
      } else if (data == 'RSVD') {
        const body = {
          tenantId: parseInt(helpers.getState().auth.user.tenantId),
          toolId: data || null,
        };
        if (!isAdmin) {
          body.userlocationid = helpers.getState().auth.user.locationId;
        }
        const response = await getReservedToolsAPICall(body);

        actions.setReservedToolsAction(response.data);
      } else {
        const body = {
          tenantId: helpers.getState().auth.user.tenantId,
          statusCode: data,
        };
        if (!isAdmin) {
          body.userlocationid = helpers.getState().auth.user.locationId;
        }
        const response = await filterToolsAPICall(body);
        actions.setToolsSearchResultsAction(response.data);
      }
    } catch (err) {
      console.log(err);
      actions.setAlertThunk({
        type: 'error',
        message: err.response.data,
      });
    }
    actions.setLoadingAction(false);
  }),
  getToolInspectionHistoryThunk: thunk(async (actions, data, helpers) => {
    actions.setLoadingAction(true);
    try {
      const response = await getInspectionHistoryAPICall({
        id: helpers.getState().tools.input.id,
        tenantid: parseInt(helpers.getState().tenant.input.id),
      });

      const organizedHistory = response.data.reduce((acc, item) => {
        if (acc[item.inspectionName]) {
          acc[item.inspectionName].push(item);
        } else {
          acc[item.inspectionName] = [item];
        }
        return acc;
      }, {});

      actions.setToolInspectionHistoryAction(organizedHistory);
    } catch (err) {
      console.log(err);
      actions.setAlertThunk({
        type: 'error',
        message: err.response.data,
      });
    }
    actions.setLoadingAction(false);
  }),
  getReservationHistoryThunk: thunk(async (actions, data, helpers) => {
    actions.setLoadingAction(true);
    try {
      const response = await getReservedToolsAPICall({
        toolId: helpers.getState().tools.input.id,
        tenantId: parseInt(helpers.getState().tenant.input.id),
      });

      actions.setReservedToolsAction(response.data);
    } catch (err) {
      console.log(err);
      actions.setAlertThunk({
        type: 'error',
        message: err.response.data,
      });
    }
    actions.setLoadingAction(false);
  }),
  getReservedToolsThunk: thunk(async (actions, data, helpers) => {
    actions.setLoadingAction(true);
    try {
      const isAdmin = helpers
        .getState()
        .auth.user.role.toLowerCase()
        .includes('admin');

      const body = {
        tenantId: parseInt(helpers.getState().auth.user.tenantId),
        toolId: data || null,
      };
      if (!isAdmin) {
        body.userlocationid = helpers.getState().auth.user.locationId;
      }
      const response = await getReservedToolsAPICall(body);

      actions.setReservedToolsAction(response.data);
    } catch (err) {
      console.log(err);
      actions.setAlertThunk({
        type: 'error',
        message: err.response.data,
      });
    }
    actions.setLoadingAction(false);
  }),
  getToolPrecheckThunk: thunk(async (actions, data, helpers) => {
    try {
      const response = await checkToolBeforeRequestAPICall({
        toolId: helpers.getState().tools.checkinToolList.length
          ? helpers.getState().tools.checkinToolList
          : helpers.getState().tools.checkoutToolList,
        tenantId: helpers.getState().auth.user.tenantId,
      });
      console.log(response);

      if (response.data.length > 0) {
        let arr = [];
        response.data.map((item) => {
          console.log(item);
          arr.push({
            reservationJSON:
              item.reservationJSON == null
                ? null
                : JSON.parse(item.reservationJSON),
            inspectionJSON:
              item.inspectionJSON == null
                ? null
                : JSON.parse(item.inspectionJSON),
          });
        });
        actions.setToolPrecheckAction(arr);
      }
    } catch (err) {
      console.log(err);
      actions.setAlertThunk({
        type: 'error',
        message: err.response.data,
      });
    }
  }),
  reportBrokenToolThunk: thunk(async (actions, data, helpers) => {
    actions.setLoadingAction(true);

    try {
      if (helpers.getState().document.body !== '') {
        const res = await upsertDocumentAPICall({
          ...helpers.getState().document,
          tenantId:
            helpers.getState().tenant.input.id ||
            helpers.getState().auth.user.tenantId,
          toolid: helpers.getState().tools.brokenToolInput.toolId,
        });

        const repID = res.data.id;

        await upsertBrokenToolAPICall({
          // ...helpers.getState().tools.brokenToolInput,
          toolId: helpers.getState().tools.brokenToolInput.toolId,
          tenantId:
            helpers.getState().tenant.input.id ||
            helpers.getState().auth.user.tenantId,
          repositoryId: repID,
          toolStatusCode:
            helpers.getState().tools.brokenToolInput.toolStatusCode,
          toolStatusDescription:
            helpers.getState().tools.brokenToolInput.toolStatusDescription,
          toolbrokendescription:
            helpers.getState().tools.brokenToolInput.toolbrokendescription,
          brokentoolcategoryDescription:
            helpers.getState().tools.brokenToolInput
              .brokentoolcategoryDescription,
          toolbrokenCategoryCode:
            helpers.getState().tools.brokenToolInput.toolbrokenCategoryCode,
        });

        actions.setBrokenToolModalVisibleAction(false);

        // const response = await getToolsAPICall({
        //   id: 0,
        //   tenantId: helpers.getState().tenant.input.id,
        // });
        // actions.setToolsSearchResultsAction(response.data);
        // console.log(response);

        actions.setAlertThunk({
          type: 'success',
          message: 'Unserviceable Tool Reported Successfully',
        });
        // navigation.goBack();

        actions.setBrokenToolInputAction({
          toolId: null,
          serialNumber: null,
          toolDescription: null,
          // repositoryid: null,
          toolStatusCode: 'BRKN',
          toolStatusDescription: 'Unserviceable Tool',
          toolbrokendescription: '',
          brokentoolcategoryDescription: '',
        });
      } else {
        await upsertBrokenToolAPICall({
          // ...helpers.getState().tools.brokenToolInput,
          toolId: helpers.getState().tools.brokenToolInput.toolId,
          tenantId:
            helpers.getState().tenant.input.id ||
            helpers.getState().auth.user.tenantId,
          toolStatusCode:
            helpers.getState().tools.brokenToolInput.toolStatusCode,
          toolStatusDescription:
            helpers.getState().tools.brokenToolInput.toolStatusDescription,
          toolbrokendescription:
            helpers.getState().tools.brokenToolInput.toolbrokendescription,
          brokentoolcategoryDescription:
            helpers.getState().tools.brokenToolInput
              .brokentoolcategoryDescription,

          toolbrokenCategoryCode:
            helpers.getState().tools.brokenToolInput.toolbrokenCategoryCode,

          repositoryId: null,
        });

        // await upsertBrokenToolAPICall({
        //   ...helpers.getState().tools.brokenToolInput,
        //   tenantId: helpers.getState().tenant.input.id,
        //   toolId: helpers.getState().tools.brokenToolInput.toolId,
        // });

        actions.setBrokenToolModalVisibleAction(false);

        actions.setAlertThunk({
          type: 'success',
          message: 'Unserviceable Tool Reported Successfully',
        });

        actions.setBrokenToolInputAction({
          toolId: null,
          repositoryid: null,
          toolStatusCode: 'BRKN',
          toolStatusDescription: 'Unserviceable Tool',
          toolbrokendescription: '',
          brokentoolcategoryDescription: '',
        });
      }
    } catch (err) {
      console.log(err);
      actions.setAlertThunk({
        type: 'error',
        message: err.response.data,
      });
    }

    actions.setLoadingAction(false);
  }),
  getBrokenToolHistoryThunk: thunk(async (actions, data, helpers) => {
    actions.setLoadingAction(true);
    try {
      const response = await getBrokenToolHistoryAPICall({
        id: data || 0,
        tenantId: parseInt(helpers.getState().tenant.input.id),
      });

      console.log(response);

      let tableData = [];
      let inputData = {
        toolId: null,
        repositoryid: null,
        toolStatusCode: 'BRKN',
        toolStatusDescription: 'Unserviceable Tool',
        toolbrokendescription: '',
      };

      if (response.data.length > 0) {
        inputData = {
          toolId: response.data[0].toolId,
          repositoryid: response.data[0].repositoryId,
          toolStatusCode: 'BRKN',
          toolStatusDescription: 'Unserviceable Tool',
          toolbrokendescription: response.data[0].notes,
        };

        tableData = response.data.slice(1);
      }

      const documentResponse = await getDocumentAPICall({
        tenantId: helpers.getState().tenant.input.id,
        id: response.data[0].repositoryId,
      });
      if (
        documentResponse &&
        documentResponse.data &&
        documentResponse.data.length &&
        documentResponse.data[0] &&
        documentResponse.data[0].body &&
        documentResponse.data[0].body != ''
      ) {
        actions.setDocumentAction(documentResponse.data[0]);
      }
      actions.setBrokenToolInputAction(inputData);
      actions.setBrokenToolHistoryAction(tableData);
    } catch (err) {
      console.log(err);
      actions.setAlertThunk({
        type: 'error',
        message: err.response ? err.response.data : err.message,
      });
    }
    actions.setLoadingAction(false);
  }),
  getBrokenToolThunk: thunk(async (actions, id, helpers) => {
    actions.setLoadingAction(true);

    const tenantId = helpers.getState().tenant.input.id;

    try {
      const toolResponse = await getToolsAPICall({
        id,
        tenantId,
      });
      actions.setToolsInputAction(toolResponse.data[0]);
    } catch (err) {
      console.log(err);
      actions.setAlertThunk({
        type: 'error',
        message: err.response.data,
      });
    }

    actions.setLoadingAction(false);
  }),
  getBrokenToolReportThunk: thunk(async (actions, id, helpers) => {
    actions.setLoadingAction(true);

    const tenantId = helpers.getState().tenant.input.id;

    try {
      const documentResponse = await getDocumentAPICall({
        tenantId: tenantId, //helpers.getState().tenant.input.id,
        id: helpers.getState().tools.brokenToolInput.repositoryId, //response.data[0].repositoryId,
      });
      if (
        documentResponse &&
        documentResponse.data &&
        documentResponse.data.length &&
        documentResponse.data[0] &&
        documentResponse.data[0].body &&
        documentResponse.data[0].body != ''
      ) {
        actions.setDocumentAction(documentResponse.data[0]);
      }
    } catch (err) {
      console.log(err);
      actions.setAlertThunk({
        type: 'error',
        message: err.response.data,
      });
    }

    actions.setLoadingAction(false);
  }),
  getToolCategoryListThunk: thunk(async (actions, data, helpers) => {
    actions.setLoadingAction(true);
    try {
      const response = await getToolCategoryListAPICall(
        helpers.getState().tenant.input.id ||
          helpers.getState().auth.user.tenantId
      );

      actions.setToolCategoryListAction(response.data);
    } catch (err) {
      console.log(err);
      actions.setAlertThunk({
        type: 'error',
        message: err.response ? err.response.data : err.message,
      });
    }
  }),
  upsertToolCategoryThunk: thunk(async (actions, navigation, helpers) => {
    actions.setLoadingAction(true);
    try {
      await upsertToolCategoryAPICall({
        ...helpers.getState().tools.toolCategoryInput,
        tenantId: helpers.getState().tenant.input.id,
      });

      actions.setAlertThunk({
        type: 'success',
        message: 'Breakage Category Added Successfully',
      });

      navigation.navigation.goBack();
    } catch (err) {
      console.log(err);
      actions.setAlertThunk({
        type: 'error',
        message: err.response ? err.response.data : err.message,
      });
    }
    actions.setLoadingAction(false);
  }),
  getOEMStatusListThunk: thunk(async (actions, data, helpers) => {
    actions.setLoadingAction(true);
    try {
      const response = await getOEMStatusListAPICall(
        helpers.getState().tenant.input.id
      );

      actions.setOEMStatusListAction(response.data);
    } catch (err) {
      console.log(err);
      actions.setAlertThunk({
        type: 'error',
        message: err.response ? err.response.data : err.message,
      });
    }
    actions.setLoadingAction(false);
  }),
  upsertOEMStatusListThunk: thunk(async (actions, navigation, helpers) => {
    actions.setLoadingAction(true);
    try {
      await upsertOEMStatusListAPICall({
        ...helpers.getState().tools.oemInput,
        tenantId: helpers.getState().tenant.input.id,
      });

      actions.setAlertThunk({
        type: 'success',
        message: 'Status Added',
      });

      navigation.goBack();
    } catch (err) {
      console.log(err);
      actions.setAlertThunk({
        type: 'error',
        message: err.response ? err.response.data : err.message,
      });
    }
    actions.setLoadingAction(false);
  }),
  getToolHistoryThunk: thunk(async (actions, toolId, helpers) => {
    actions.setLoadingAction(true);
    try {
      const response = await getToolHistoryAPICall({
        toolId,
        tenantId:
          helpers.getState().tenant.input.id ||
          helpers.getState().auth.user.tenantId,
      });
      actions.setToolHistoryAction(response.data);
      actions.setToolHistoryModalAction(true);
    } catch (err) {
      actions.setAlertThunk({
        type: 'error',
        message: err.response.data,
      });
    }

    actions.setLoadingAction(false);
  }),
  getToolTransfersThunk: thunk(async (actions, toolId, helpers) => {
    actions.setLoadingAction(true);
    try {
      const response = await getToolTransfersAPICall({
        tenantId:
          helpers.getState().tenant.input.id ||
          helpers.getState().auth.user.tenantId,
      });
      const locationResponse = await getLocationsAPICall(
        helpers.getState().tenant.input.id ||
          helpers.getState().auth.user.tenantId
      );
      actions.setToolTransfersAction(response.data);

      actions.setLocationSearchResultsAction(locationResponse.data);
    } catch (err) {
      actions.setAlertThunk({
        type: 'error',
        message: err.response.data,
      });
    }

    actions.setLoadingAction(false);
  }),
  requestToolTransferThunk: thunk(async (actions, transfer, helpers) => {
    try {
      const response = await upsertToolTransferAPICall(transfer);
      return response.data[0][0];
    } catch (err) {
      console.log(err);
      actions.setAlertThunk({
        type: 'error',
        message: err.response ? err.response.data : err.message,
      });
    }
  }),
  upsertToolTransferThunk: thunk(async (actions, input, helpers) => {
    try {
      await upsertToolTransferAPICall(input);

      actions.setAlertThunk({
        type: 'success',
        message: 'Tool Transfer Added',
      });
    } catch (err) {
      console.log(err);
      actions.setAlertThunk({
        type: 'error',
        message: err.response ? err.response.data : err.message,
      });
    }
  }),
  removeToolTransferThunk: thunk(async (actions, transfer, helpers) => {
    try {
      await removeToolTransfersAPICall({
        id: transfer.id,
        tenantId: transfer.tenantid,
      });
      actions.setAlertThunk({
        type: 'success',
        message: 'Tool Transfer Removed',
      });
    } catch (err) {
      console.log(err);
      actions.setAlertThunk({
        type: 'error',
        message: err.response ? err.response.data : err.message,
      });
    }
  }),
  upsertAllToolStatusThunk: thunk(async (actions, data, helpers) => {
    actions.setLoadingAction(true);
    try {
      await upsertAllToolStatusAPICall({
        ...data,
        tenantId:
          helpers.getState().tenant.input.id ||
          helpers.getState().auth.user.tenantId,
      });
      actions.setAlertThunk({
        type: 'success',
        message: 'Tool Status Updated',
      });
    } catch (err) {
      console.log(err);
      actions.setAlertThunk({
        type: 'error',
        message: err.response ? err.response.data : err.message,
      });
    }
    actions.setLoadingAction(false);
  }),
  getReportsThunk: thunk(async (actions, toolId, helpers) => {
    actions.setReportsLoading(true);
    try {
      const [toolsResponse, reportsResponse, locationResponse] =
        await Promise.all([
          getToolsAPICall({
            id: 0,
            tenantId:
              helpers.getState().tenant.input.id ||
              helpers.getState().auth.user.tenantId,
          }),
          getUpcomingReportsAPICall({
            tenantId:
              helpers.getState().tenant.input.id ||
              helpers.getState().auth.user.tenantId,
          }),
          getLocationsAPICall(helpers.getState().tenant.input.id),
        ]);

      actions.setLocationSearchResultsAction(locationResponse.data);
      actions.setToolsSearchResultsAction(toolsResponse.data);
      actions.setReportsAction(reportsResponse.data);
    } catch (err) {
      actions.setAlertThunk({
        type: 'error',
        message: err.response.data,
      });
    }

    actions.setReportsLoading(false);
  }),
  getToolsByEngineThunk: thunk(async (actions, serialNumber, helpers) => {
    actions.setEngineReportLoading(true);
    try {
      const response = await getToolsByEngineAPICall({
        tenantId:
          helpers.getState().tenant.input.id ||
          helpers.getState().auth.user.tenantId,
        serialNumber,
      });
      actions.setToolsByEngineAction(response.data);
    } catch (err) {
      actions.setAlertThunk({
        type: 'error',
        message: err.response.data,
      });
    }

    actions.setEngineReportLoading(false);
  }),
  getToolUsageThunk: thunk(
    async (actions, { toolId, allrecordsflag }, helpers) => {
      actions.setLoadingAction(true);
      try {
        const tools = helpers.getState().workPackageTools.searchResults;
        let ids = tools.map((item) => item.toolId).join();
        const usageList = await getToolUsageAPICall({
          toolId: toolId || ids,
          tenantId:
            helpers.getState().tenant.input.id ||
            helpers.getState().auth.user.tenantId,
          allrecordsflag,
        });
        actions.setToolUsageListAction(usageList.data);
      } catch (err) {
        actions.setAlertThunk({
          type: 'error',
          message: err.response.data,
        });
      }

      actions.setLoadingAction(false);
    }
  ),
  upsertToolUsageThunk: thunk(
    async (actions, { isCheckIn, tools, isWP = false }, helpers) => {
      actions.setLoadingAction(true);
      let workOrderDetails = helpers.getState().tools.checkoutInput;
      let workOrderPackageDetails =
        helpers.getState().workPackage.checkoutInput;
      let checkoutComments = helpers.getState().tools.checkoutComment;
      let checkInComments = helpers.getState().tools.checkInComment;
      let usageMap = helpers.getState().tools.usageMap;
      let tenantId =
        helpers.getState().tenant.input.id ||
        helpers.getState().auth.user.tenantId;
      try {
        let requests = [];
        tools.forEach((tool) => {
          let body = {};
          if (!isCheckIn) {
            body = {
              id: null,
              tenantId,
              toolId: tool.id || tool.toolid,
              tenantLocation: tool.tenantLocation,
              storageLocation: tool.storageLocation || tool.storagelocation,
              checkoutcomments: checkoutComments,
              checkincomments: null,
              engineserialnumber:
                workOrderDetails.engineserialNumber ||
                workOrderPackageDetails.engineserialNumber,
              workordernumber:
                workOrderDetails.workOrder || workOrderPackageDetails.workOrder,
              checkindate: null,
              checkoutdate: new Date(),
            };
          } else {
            if (isWP) {
              body = usageMap[tool.toolId];
            } else {
              body = usageMap[tool.id];
            }
            body.checkindate = new Date();
            body.checkincomments = checkInComments;
          }

          requests.push(upsertToolUsageAPICall(body));
        });

        await Promise.all(requests);

        actions.setCheckoutCommentAction('');
        actions.setCheckInCommentAction('');
      } catch (err) {
        actions.setAlertThunk({
          type: 'error',
          message: err.response.data,
        });
      }

      actions.setLoadingAction(false);
    }
  ),
};

export default thunks;
