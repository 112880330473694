import { axios } from '.';
import { convertJsonToQueryString } from '../utilities/general';
import { queryString } from 'querystring';

const toolsAxios = axios.create({
  // baseURL: `https://tmpbackenduat.azurewebsites.net/tool`,
  baseURL: `${process.env.REACT_APP_BACKEND_BASE_URL}/tool`,
});

function getToolsAPICall({ id, tenantId, userlocationid }) {
  let params = '';
  if (id) {
    params = `?id=${id}&tenantId=${tenantId}`;
  } else {
    params = `?tenantId=${tenantId}`;
  }

  if (userlocationid) {
    params += `&userlocationid=${userlocationid}`;
  }

  return toolsAxios({
    method: 'get',
    url: `/get${params}`,
  });
}

function upsertToolsAPICall(data) {
  return toolsAxios({ method: 'put', url: `/upsert`, data });
}

function upsertBulkToolsAPICall(data) {
  return toolsAxios({ method: 'put', url: `/upsert/bulk`, data });
}

function upsertAllToolStatusAPICall(data) {
  return toolsAxios({ method: 'put', url: `/upsert/status`, data });
}

function getToolStatusAPICall(data) {
  return toolsAxios({ method: 'get', url: `/status/list/${data}` });
}

function upsertToolStatusAPICall(data) {
  return toolsAxios({ method: 'put', url: `/status/list/upsert`, data });
}

function checkoutToolAPICall(data) {
  return toolsAxios({ method: 'put', url: `/status/update`, data });
}
function reserveToolAPICall(data) {
  return toolsAxios({ method: 'put', url: `/reservation/upsert`, data });
}
function getReservedToolsAPICall(queryString) {
  return toolsAxios({
    method: 'get',
    url: `/reservations/get?${convertJsonToQueryString(queryString)}`,
  });
}

function filterToolsAPICall(queryString) {
  return toolsAxios({
    method: 'get',
    url: `/statuscode/get?${convertJsonToQueryString(queryString)}`,
  });
}

function checkToolBeforeRequestAPICall(queryString) {
  return toolsAxios({
    method: 'get',
    url: `/precheck/get?${convertJsonToQueryString(queryString)}`,
  });
}

function removeToolFromWorkPackageAPICall(queryString) {
  return toolsAxios({
    method: 'delete',
    url: `/deletefromworkpackage?${convertJsonToQueryString(queryString)}`,
  });
}

function upsertBrokenToolAPICall(data) {
  return toolsAxios({ method: 'put', url: `/broken/upsert`, data });
}

function getBrokenToolHistoryAPICall(queryString) {
  return toolsAxios({
    method: 'get',
    url: `/broken/history/get?${convertJsonToQueryString(queryString)}`,
  });
}

function getToolStorageLocationAPICall(queryString) {
  return toolsAxios({
    method: 'get',
    url: `/storagelocation/get?${convertJsonToQueryString(queryString)}`,
  });
}
function upsertToolStorageLocationAPICall(data) {
  return toolsAxios({ method: 'put', url: `/storagelocation/upsert`, data });
}

function getToolCategoryListAPICall(data) {
  return toolsAxios({ method: 'get', url: `/categories/get/${data}` });
}

function upsertToolCategoryAPICall(data) {
  return toolsAxios({ method: 'put', url: `/category/upsert`, data });
}

function bulkUploadWorkPackageToolsAPICall(data) {
  return toolsAxios({
    method: 'put',
    url: '/workpackage/upsert/bulk',
    data,
  });
}

function getOEMStatusListAPICall(id) {
  return toolsAxios({
    method: 'get',
    url: `/oemstatuslist/${id}`,
  });
}

function upsertOEMStatusListAPICall(data) {
  return toolsAxios({
    method: 'put',
    url: `/oemstatus/upsert`,
    data,
  });
}

function getToolHistoryAPICall({ toolId, tenantId }) {
  return toolsAxios({
    method: 'get',
    url: `/history?toolId=${toolId}&tenantId=${tenantId}`,
  });
}

function getToolTransfersAPICall({ toolId, tenantId }) {
  let url = `/transfer?tenantId=${tenantId}`;
  if (toolId) {
    url += `&toolId=${toolId}`;
  }
  return toolsAxios({
    method: 'get',
    url: url,
  });
}

function upsertToolTransferAPICall(data) {
  return toolsAxios({
    method: 'put',
    url: `/transfer`,
    data,
  });
}

function removeToolTransfersAPICall({ id, tenantId }) {
  let url = `/transfer?id=${id}&tenantId=${tenantId}`;
  return toolsAxios({
    method: 'delete',
    url: url,
  });
}

function upsertToolUsageAPICall(data) {
  return toolsAxios({
    method: 'put',
    url: `/usage`,
    data,
  });
}

function getToolUsageAPICall({ toolId, tenantId, allrecordsflag }) {
  let url = `/usage?tenantId=${tenantId}`;
  if (toolId) {
    url += `&toolId=${toolId}`;
  }
  url += `&allrecordsflag=${allrecordsflag}`;
  return toolsAxios({
    method: 'get',
    url: url,
  });
}

function overrideToolStatusAPICall(data) {
  return toolsAxios({
    method: 'post',
    url: `/override/status`,
    data,
  });
}

export {
  toolsAxios,
  upsertToolsAPICall,
  getToolsAPICall,
  upsertBulkToolsAPICall,
  getToolStatusAPICall,
  upsertToolStatusAPICall,
  checkoutToolAPICall,
  reserveToolAPICall,
  filterToolsAPICall,
  getReservedToolsAPICall,
  checkToolBeforeRequestAPICall,
  removeToolFromWorkPackageAPICall,
  upsertBrokenToolAPICall,
  getBrokenToolHistoryAPICall,
  getToolStorageLocationAPICall,
  upsertToolStorageLocationAPICall,
  getToolCategoryListAPICall,
  upsertToolCategoryAPICall,
  bulkUploadWorkPackageToolsAPICall,
  getOEMStatusListAPICall,
  upsertOEMStatusListAPICall,
  getToolHistoryAPICall,
  getToolTransfersAPICall,
  upsertToolTransferAPICall,
  removeToolTransfersAPICall,
  upsertAllToolStatusAPICall,
  upsertToolUsageAPICall,
  getToolUsageAPICall,
  overrideToolStatusAPICall,
};
