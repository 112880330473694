import { Tooltip } from '@rneui/themed';
import { Fragment, useState } from 'react';
import Modal from 'modal-react-native-web';
import ControlledTooltip from '../../presentational/Tooltip';
import Text from '../../presentational/Text';
import Icon from '../../presentational/Icon';
import { FontAwesome5 } from '@expo/vector-icons';

function ToolCancelledTooltip({
  text,
  popoverText = 'Tool Cancelled',
  isUnderlined = false,
  isCancelled = false,
}) {
  return (
    <Fragment>
      <Text
        style={{
          color: isCancelled ? '#FF190C' : '#00205b',
          textDecoration: isUnderlined ? 'underline' : 'none',
        }}
      >
        {text}
        {isCancelled && (
          <ControlledTooltip
            ModalComponent={Modal}
            popover={<Text>{popoverText}</Text>}
            withPointer={false}
            backgroundColor={'#f8f9fa'}
            containerStyle={{
              borderRadius: '12px',
              boxShadow: '0px 0px 5px grey',
            }}
          >
            <Icon
              Component={FontAwesome5}
              name='exclamation-circle'
              size={20}
              style={{
                color: '#FF190C',
                paddingLeft: '8px',
              }}
            ></Icon>
          </ControlledTooltip>
        )}
      </Text>
    </Fragment>
  );
}

export default ToolCancelledTooltip;
