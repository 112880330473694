import Page from '../containers/Page';
import { Fragment } from 'react';
import SearchBar from '../containers/SearchBar';
import List from '../containers/List';
import Row from '../presentational/Row';
import Col from '../presentational/Col';
import { useToolTransfer } from '../../hooks';
import { ScrollView } from 'react-native-gesture-handler';
import Form from '../containers/Form';

function ToolTransferPage({ navigation, ...props }) {
  const {
    fromLocations,
    loading,
    storageOptions,
    tenantOptions,
    toLocations,
    toolOptions,
    toolStatusOptions,
    transferInput,
    approveToolTransfer,
    clearTransfer,
    getTools,
    receiveTransfer,
    rejectTransfer,
    requestToolTransfer,
    selectTenant,
    selectToolTransfer,
    setInput,
  } = useToolTransfer();

  return (
    <Fragment>
      <Page.Default
        title='Tool Transfers'
        loading={loading}
        navBar={
          <Fragment>
            <Row>
              <Col xs={12}>
                <SearchBar.Transfers />
              </Col>
            </Row>
            <Row style={{ flex: 1, height: '100%' }}>
              <Col xs={12} md={12} style={{ flex: 1, height: '100%' }}>
                <ScrollView
                  style={{ flex: 1 }}
                  contentContainerStyle={{ flexGrow: 1 }}
                >
                  <List.Transfers
                    selectTransfer={selectToolTransfer}
                    current={transferInput}
                  />
                </ScrollView>
              </Col>
            </Row>
          </Fragment>
        }
        body={
          <Form.ToolTransfer
            approveTransfer={approveToolTransfer}
            fromLocations={fromLocations}
            getTools={getTools}
            input={transferInput}
            newTransfer={clearTransfer}
            receiveTransfer={receiveTransfer}
            rejectTransfer={rejectTransfer}
            requestTransfer={requestToolTransfer}
            selectTenant={selectTenant}
            setInput={setInput}
            storageLocations={storageOptions}
            tenantOptions={tenantOptions}
            toLocations={toLocations}
            tools={toolOptions}
            toolStatuses={toolStatusOptions}
          ></Form.ToolTransfer>
        }
      ></Page.Default>
    </Fragment>
  );
}

export default ToolTransferPage;
