import { thunk } from 'easy-peasy';
import {
  getLocationAPICall,
  getLocationsAPICall,
  upsertLocationAPICall,
} from '../../api/locations';
import {
  getToolStorageLocationAPICall,
  upsertToolStorageLocationAPICall,
} from '../../api/tool';

const thunks = {
  getStorageLocationsThunk: thunk(async (actions, tenantId, helpers) => {
    actions.setLoadingAction(true);

    try {
      const response = await getToolStorageLocationAPICall({
        tenantId: parseInt(helpers.getState().tenant.input.id),
        id: null,
      });
      actions.setStorageLocationSearchResultsAction(response.data);
    } catch (err) {
      actions.setAlertThunk({
        type: 'error',
        message: err.response.data,
      });
    }

    actions.setLoadingAction(false);
  }),

  getStorageLocationThunk: thunk(async (actions, tenantId, helpers) => {
    try {
      const response = await getToolStorageLocationAPICall({
        tenantId: tenantId,
      });
      return response.data;
    } catch (err) {
      actions.setAlertThunk({
        type: 'error',
        message: err.response.data,
      });
    }
  }),

  upsertStorageLocationThunk: thunk(
    async (actions, { navigation, input, message }, helpers) => {
      actions.setLoadingAction(true);

      try {
        await upsertToolStorageLocationAPICall({
          // ...input,
          ...helpers.getState().storageLocation.input,
          tenantId: parseInt(helpers.getState().tenant.input.id),
        });
        navigation.goBack();
        actions.setAlertThunk({
          type: 'success',
          message,
        });
        // navigation.navigate('Locations');
      } catch (err) {
        actions.setAlertThunk({
          type: 'error',
          message: err.response.data,
        });
      }

      actions.setLoadingAction(false);
    }
  ),
};

export default thunks;
