import CustomModal from '../../presentational/Modal';
import ToolCheckinModal from './CheckinToolModal';
import ToolCheckoutModal from './CheckoutToolModal';
import DocumentModal from './DocumentModal';
import InspectionQuestionsModal from './InspectionQuestionsModal';
import PreCheckWarningModal from './PreCheckWarningModal';
import ReservationModal from './ReservationModal';
import WorkOrderSerialModal from './WorkOrderSerialModal';
import WorkPackageSerialModal from './WorkPackageWOSerialModal';
import AreYouSureModal from './AreYouSureModal';
import WorkPackageReservationModal from './WorkPackageReservationModal';
import AddBrokenToolModal from './AddBrokenToolModal';
import AreYouSureWorkPackageModal from './AreYouSureModalWorkPackageModal';
import AreYouSureWorkPackageToolModal from './AreYouSureModalWorkPackageToolModal';
import AreYouSureInspectionTypeModal from './AreYouSureInspectionTypeModal';
import AreYouSureInspectionItemModal from './AreYouSureInspectionItemModal';
import InspectionHistoryModal from './InspectionHistoryModal';
import PrecheckWorkPackageToolsCheckoutModal from './PrecheckWorkPackageToolModal';
import PreCheckWPWarningModal from './PreCheckWPWarningModal';
import ForcePasswordChangeModal from './ForcePasswordChangeModal';
import CopyWorkPackageToTenantModal from './CopyWorkPackageToTenantModal';
import CopyWPToTenantListModal from './CopyWPToTenantListModal';
import BarcodeScannerModal from './BarcodeScannerModal';
import ToolInformationModal from './ToolInformationModal';
import DocumentListImageModal from './DocumentListImageModal';
import AreYouSureWorkWPReservation from './AreYouSureModalWPReservations';
import AddInspectionModal from './AddInspectionModal';
import ToolHistoryModal from './ToolHistoryModal';
import ScoreCardModal from './ScoreCardModal';
import ReportModal from './ReportModal';
import UpsertVariantModal from './UpsertVariantModal';
import EngineReportModal from './EngineReportModal';
import EngineSelectModal from './EngineSelectModal';

CustomModal.Reservation = ReservationModal;
CustomModal.CheckoutTool = ToolCheckoutModal;
CustomModal.InspectionQuestions = InspectionQuestionsModal;
CustomModal.Document = DocumentModal;
CustomModal.CheckinTool = ToolCheckinModal;
CustomModal.PreCheckWarning = PreCheckWarningModal;

CustomModal.WorkOrderSerial = WorkOrderSerialModal;
CustomModal.WorkPackageWOSerial = WorkPackageSerialModal;
CustomModal.AreYouSure = AreYouSureModal;

CustomModal.AreYouSureWorkPackage = AreYouSureWorkPackageModal;
CustomModal.AreYouSureWorkPackageTool = AreYouSureWorkPackageToolModal;
CustomModal.AreYouSureInspectionType = AreYouSureInspectionTypeModal;
CustomModal.AreYouSureInspectionItem = AreYouSureInspectionItemModal;

CustomModal.WorkPackageReservation = WorkPackageReservationModal;
CustomModal.BrokenTool = AddBrokenToolModal;
CustomModal.InspectionHistory = InspectionHistoryModal;

CustomModal.PrecheckWorkPackage = PrecheckWorkPackageToolsCheckoutModal;
CustomModal.WPPrecheckInformationModal = PreCheckWPWarningModal;

CustomModal.PasswordChange = ForcePasswordChangeModal;

CustomModal.CopyWorkPackageToTenant = CopyWorkPackageToTenantModal;
CustomModal.CopyWorkPackageToolList = CopyWPToTenantListModal;

CustomModal.Barcode = BarcodeScannerModal;

CustomModal.ToolInformation = ToolInformationModal;
CustomModal.DocImage = DocumentListImageModal;
CustomModal.CancelWPReservation = AreYouSureWorkWPReservation;

CustomModal.AddInspection = AddInspectionModal;

CustomModal.ToolHistory = ToolHistoryModal;
CustomModal.ScoreCard = ScoreCardModal;
CustomModal.Reports = ReportModal;
CustomModal.UpsertVariant = UpsertVariantModal;
CustomModal.EngineReport = EngineReportModal;
CustomModal.EngineSelect = EngineSelectModal;

export default CustomModal;
