import { useStoreState } from 'easy-peasy';
import { Fragment } from 'react';
import { ListItem } from '@rneui/themed';
import Text from '../../presentational/Text';

export default function ToolTransfersList({ selectTransfer, current }) {
  const hasSearchText = (value, searchInput) =>
    value?.toString().toLowerCase().trim().includes(searchInput.toLowerCase());

  const {
    location: { locationMap },
  } = useStoreState((state) => state);

  const { toolTransfers } = useStoreState((state) => ({
    ...state.tools,
    toolTransfers: (state.tools.toolTransfers || []).filter(
      (tool) =>
        hasSearchText(tool?.partNumber, state.tools.transferSearchInput) ||
        hasSearchText(tool?.serialNumber, state.tools.transferSearchInput) ||
        hasSearchText(tool?.status, state.tools.transferSearchInput) ||
        hasSearchText(tool?.trackingnumber, state.tools.transferSearchInput) ||
        hasSearchText(tool?.amount, state.tools.transferSearchInput) ||
        hasSearchText(
          locationMap[tool.fromtoollocationid]?.name,
          state.tools.transferSearchInput
        ) ||
        hasSearchText(
          locationMap[tool.totoollocationid]?.name,
          state.tools.transferSearchInput
        )
    ),
  }));

  return (
    <Fragment>
      {toolTransfers?.map((tool) => {
        return (
          <ListItem
            onPress={() => selectTransfer(tool)}
            style={{
              borderLeft: tool.id === current.id ? '6px solid #0088CE' : '',
              boxShadow: '0px 0px 5px grey',
              borderRadius: '12px',
              overflow: 'hidden',
              margin: '8px',
            }}
          >
            <ListItem.Content>
              <ListItem.Title>
                <Text
                  style={{
                    color: '#00205B',
                    fontSize: '1.6vh',
                    fontWeight: '600',
                  }}
                >
                  {tool.partNumber} ({tool.serialNumber})
                </Text>
              </ListItem.Title>
              <ListItem.Subtitle>
                <Text
                  style={{
                    color: '#76787A',
                    fontSize: '1.4vh',
                    fontWeight: '600',
                  }}
                >
                  {tool.status}
                </Text>
              </ListItem.Subtitle>
            </ListItem.Content>
          </ListItem>
        );
      })}
    </Fragment>
  );
}
