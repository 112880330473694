import { thunk } from 'easy-peasy';
import { getToolStatusAPICall, upsertToolStatusAPICall } from '../../api/tool';

const thunks = {
  getToolStatusThunk: thunk(async (actions, tenantId, helpers) => {
    actions.setLoadingAction(true);
    try {
      const response = await getToolStatusAPICall(1);
      actions.setToolStatusSearchResultsAction(response.data);
    } catch (err) {
      actions.setAlertThunk({
        type: 'error',
        message: err.response.data,
      });
    }

    actions.setLoadingAction(false);
  }),
  getToolStatusesThunk: thunk(async (actions, tenantId, helpers) => {
    try {
      const response = await getToolStatusAPICall(1);
      return response.data;
    } catch (err) {
      actions.setAlertThunk({
        type: 'error',
        message: err.response.data,
      });
    }
  }),
  upsertToolStatusThunk: thunk(
    async (actions, { navigation, input, message }, helpers) => {
      actions.setLoadingAction(true);

      try {
        await upsertToolStatusAPICall({
          ...input,
          tenantId: 1,
        });

        actions.setAlertThunk({
          type: 'success',
          message,
        });
        navigation.goBack();
      } catch (err) {
        actions.setAlertThunk({
          type: 'error',
          message: err.response.data,
        });
      }

      actions.setLoadingAction(false);
    }
  ),
};

export default thunks;
