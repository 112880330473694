import { thunk } from 'easy-peasy';
import { getMessagesAPICall, upsertMessageAPICall } from '../../api/message';
import { MessageStatus } from './state';
import {
  getCommentsAPICall,
  removeCommentsAPICall,
  upsertCommentAPICall,
} from '../../api/comment';
import { overrideToolStatusAPICall } from '../../api/tool';

const thunks = {
  getMessagesThunk: thunk(async (actions, data, helpers) => {
    try {
      const response = await getMessagesAPICall({
        tenantId:
          helpers.getState().tenant.input.id ||
          helpers.getState().auth.user.tenantId,
      });
      actions.setMessagesSearchResultsAction(response.data);
      actions.setUnreadMessageCount(response.data);
    } catch (err) {
      actions.setAlertThunk({
        type: 'error',
        message: err.response.data,
      });
    }
  }),
  sendMessageThunk: thunk(async (actions, input, helpers) => {
    try {
      const message = await upsertMessageAPICall(input);
      return message.data;
    } catch (err) {
      console.log(err);
      actions.setAlertThunk({
        type: 'error',
        message: err.response.data,
      });
    }
  }),
  upsertMessageThunk: thunk(async (actions, input, helpers) => {
    try {
      const message = await upsertMessageAPICall({
        ...input,
        id: input.messageID,
        tenantId: input.tenantID,
        user: input.createdBy,
      });
      actions.updateSearchResultsAction(message.data);
      actions.setAlertThunk({
        type: 'success',
        message: 'Messaged Updated',
      });
    } catch (err) {
      console.log(err);
      actions.setAlertThunk({
        type: 'error',
        message: err.response.data,
      });
    }
  }),
  markAllAsReadThunk: thunk(async (actions, input, helpers) => {
    actions.setLoadingAction(true);
    try {
      const requests = [];
      const messages = helpers
        .getState()
        .messages.searchResults.filter(
          (item) => item.messagestatusID === MessageStatus.Unread
        );
      messages.forEach((message) => {
        requests.push(
          upsertMessageAPICall({
            ...message,
            id: message.messageID,
            tenantId: message.tenantID,
            user: message.createdBy,
            messagestatusID: parseInt(MessageStatus.Read),
          })
        );
      });

      await Promise.all(requests);
      const response = await getMessagesAPICall({
        tenantId:
          helpers.getState().tenant.input.id ||
          helpers.getState().auth.user.tenantId,
      });
      actions.setMessagesSearchResultsAction(response.data);

      actions.setAlertThunk({
        type: 'success',
        message: 'Messaged Updated',
      });
    } catch (err) {
      console.log(err);
      actions.setAlertThunk({
        type: 'error',
        message: err.response.data,
      });
    }
    actions.setLoadingAction(false);
  }),
  getCommentsThunk: thunk(async (actions, { messageId }, helpers) => {
    actions.setMessageLoadingAction(true);
    try {
      const response = await getCommentsAPICall({
        messageId: messageId,
      });
      actions.setCommentsAction(response.data);
    } catch (err) {
      actions.setAlertThunk({
        type: 'error',
        message: err.response.data,
      });
    }

    actions.setMessageLoadingAction(false);
  }),
  upsertCommentThunk: thunk(async (actions, comment, helpers) => {
    actions.setMessageLoadingAction(true);
    try {
      await upsertCommentAPICall({
        ...comment,
        user: `${helpers.getState().auth.user.firstName} ${
          helpers.getState().auth.user.lastName
        }`,
      });
      const response = await getCommentsAPICall({
        messageId: comment.messageID,
      });
      actions.setCommentsAction(response.data);
    } catch (err) {
      actions.setAlertThunk({
        type: 'error',
        message: err.response.data,
      });
    }

    actions.setMessageLoadingAction(false);
  }),
  removeCommentThunk: thunk(
    async (actions, { commentId, messageId }, helpers) => {
      actions.setMessageLoadingAction(true);
      try {
        await removeCommentsAPICall({
          commentId,
          messageId,
        });
        const response = await getCommentsAPICall({
          messageId: messageId,
        });
        actions.setCommentsAction(response.data);
      } catch (err) {
        actions.setAlertThunk({
          type: 'error',
          message: err.response.data,
        });
      }

      actions.setMessageLoadingAction(false);
    }
  ),
  overrideToolStatusThunk: thunk(async (actions, input, helpers) => {
    actions.setMessageLoadingAction(true);
    try {
      const selectedMessage = helpers.getState().messages.selectedMessage;
      await overrideToolStatusAPICall({
        tenantId: selectedMessage.tenantID,
        partNumber: selectedMessage.partNumber,
        status: 'Override',
      });
      actions.setAlertThunk({
        type: 'success',
        message: 'Tools Overridden',
      });
    } catch (err) {
      console.log(err);
      actions.setAlertThunk({
        type: 'error',
        message: err.response.data,
      });
    }
    actions.setMessageLoadingAction(false);
  }),
};

export default thunks;
